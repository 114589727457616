html,
body {
	margin: 0;
	height: 100%;
	overflow: hidden;
}

.App {
	text-align: center;
	font-family: "Playwrite DE Grund", cursive;
	font-optical-sizing: auto;
	font-weight: 300;
	font-style: normal;
	color: #eaeaea;
}

.prevent-select {
	-webkit-user-select: none; /* Safari */
	-ms-user-select: none; /* IE 10 and IE 11 */
	user-select: none; /* Standard syntax */
	font-size: 36px;
	margin-top: 20%;
}

#particles canvas {
	z-index: -1;
	height: 100%;
	width: 100%;
	position: absolute;
}

@media only screen and (max-width: 391px) {
	.App h1 {
		position: absolute;
		margin-left: 50%;
		translate: -50%;
		top: 24%;
		font-size: 40px;
	}
	.App p {
		position: absolute;
		margin-left: 50%;
		translate: -50%;
		top: 48%;
		font-size: 22px;
	}
}
